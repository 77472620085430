@tailwind base;
@tailwind components;
@tailwind utilities;


@import url('https://fonts.googleapis.com/css2?family=DM+Serif+Text&family=IBM+Plex+Mono&display=swap');


@layer base {
    body {
        @apply font-['IBM_Plex_Mono'];
    }
    li {
        @apply px-4;
        @apply cursor-pointer
    }
    h1 {
        @apply font-['DM_Serif_Text'];
    }
}

.content-div {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 200px;
}

.content-div:hover {
    background-image: linear-gradient(
        to right,
        #A66964,
        #804E49   
    )!important;
}